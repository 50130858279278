.css-18lrjg1-MuiCircularProgress-root {
  color: #70B17C !important;
}
.image-circular-progress {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) !important;
}

.MuiPaper-root, .css-1160xiw-MuiPaper-root-MuiDrawer-paper {
  min-width: 328px;
  max-width: 328px;
}

.css-1160xiw-MuiPaper-root-MuiDrawer-paper .drawer-wrapper{
  overflow: auto !important;
  padding: 32px 24px 104px 24px !important;
}

.drawer-wrapper{
  overflow: auto !important;
  padding: 32px 24px 104px 24px !important;
}

.css-3bmhjh-MuiPaper-root-MuiPopover-paper{
  border-radius: 12px !important;
  box-shadow: -4px 4px 10px rgba(0, 0, 0, 0.5) !important;
  padding: 14px 10px !important;
  transform: translate(-77px, 4px) !important;
}

/* .css-3bmhjh-MuiPaper-root-MuiPopover-paper .item{
  display: flex;
  align-items: center;
  cursor: pointer;
}

.css-3bmhjh-MuiPaper-root-MuiPopover-paper .item:first-child{
  margin-bottom: 16px;
}

.css-3bmhjh-MuiPaper-root-MuiPopover-paper .item svg{
  margin-right: 6px;
} */

.filter-popover .css-3bmhjh-MuiPaper-root-MuiPopover-paper{
  transform: translate(-17px, 4px) !important;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08) !important;
  padding: 16px 14px 16px 25px !important;
}

.filter-popover .css-3bmhjh-MuiPaper-root-MuiPopover-paper .css-ahj2mt-MuiTypography-root{
  font-size: 14px;
  font-weight: 500;
}

.filter-popover .filter-item{
  margin-bottom: 5px;
}

.filter-popover .filter-item:last-of-type{
  margin-bottom: 0;
}

.filter-popover .children{
  margin-left: 20px;
  margin-top: 5px;
}

.filter-popover .child{
  margin-bottom: 5px;
}

.filter-popover .child:last-of-type{
  margin-bottom: 0;
}

.btn-height{
  height: 48px !important;
}

/* ------------ */

.d-flex {
  display: flex;
}
.d-block {
  display: block;
}
.d-none{
  display: none;
}
.pointer {
  cursor: pointer;
}

.overflow-hidden {
  overflow: hidden !important;
}
.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 12px;
}
@media screen and (max-width: 1400px) {
  .container {
    max-width: 88%;
  }
}
@media screen and (max-width: 1200px) {
  .container {
    max-width: 93%;
  }
}
@media screen and (min-width: 1400px) {
  .container {
    max-width: 1300px;
  }
}
.page-title{
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
}
.drawer-title{
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
}
.custom-text{
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #232323;
}
.hover:hover {
  cursor: pointer;
}
.align-items-center {
  align-items: center;
}
.align-items-end {
  align-items: end;
}
.align-items-start {
  align-items: start;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-start {
  justify-content: start;
}
.justify-content-end {
  justify-content: end;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-nowrap {
  white-space: nowrap;
}

.font-600 {
  font-weight: 600 !important;
}

.font-500 {
  font-weight: 500 !important;
}
.list-unstyled {
  list-style: none;
}

.font-700 {
  font-weight: 700 !important;
}
.font-size-17 {
  font-size: 17px;
}
.font-size-18 {
  font-size: 18px;
}

hr {
  opacity: 0.6;
}
.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.text-start {
  text-align: start;
}

.font-size-20 {
  font-size: 20px;
}
.customContainer {
  padding: 32px !important;
}

.paginContainer {
  padding: 32px 32px 0 32px !important;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.text-green{
  color: #70B17C !important;
}

.text-red{
  color: red !important;
}